import React from "react";

const Loading = () => {
  return (
    <div className="content py-5 h-100" >
      <div className="sk-three-bounce bg-transparent">
        <div className="sk-child sk-bounce1"></div>
        <div className="sk-child sk-bounce2"></div>
        <div className="sk-child sk-bounce3"></div>
      </div>
    </div>
  );
};

export default Loading;
