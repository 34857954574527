import React, { lazy, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import "assets/css/nucleo-icons.css";
import "assets/css/all.css";
import "assets/scss/black-dashboard-pro-react.scss";
import "assets/css/placeholder.css";
import Loading from "components/Loading/Loading";
// import Echo from "laravel-echo";
// window.pusher = require("pusher-js");

const PublicLayout = lazy(() => import("layouts/Public/Public.jsx"));
const RTLLayout = lazy(() => import("layouts/Home/RTL.jsx"));
const AuthLayout = lazy(() => import("layouts/Auth/Auth.jsx"));

const App = () => {
  // const lang = props.location.pathname.split("/")[1];

  const locale = useSelector((state) => state.intlReducer.locale, shallowEqual);
  // window.Echo = new Echo({
  //   broadcaster: "pusher",
  //   key: "myKey",
  //   wsHost: "https://api.yekpay.com",
  //   wsPort: 6001,
  //   wssport: 6001,
  //   transports: ["websocket"],
  //   enabledTransports: ["ws", "wss"],
  //   forceTLS: false,
  //   disableStats: true,
  // });
  // window.Echo.channel("chat").listen("MessageSent", (id) => {
  //   console.log(id);
  // });
  return (
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      <Switch>
        <Route
          path={
            process.env.PUBLIC_URL + "/:lang(fa|en)?/api/payment/:authority"
          }
          render={() => <PublicLayout />}
        />
        <Route
          path={process.env.PUBLIC_URL + "/:lang(fa|en)?/not-found"}
          render={() => <PublicLayout />}
        />
        <Route
          path={process.env.PUBLIC_URL + "/:lang(fa|en)?/payment-link"}
          render={() => <PublicLayout />}
        />
        <Route
          path={process.env.PUBLIC_URL + "/:lang(fa|en)?/verify"}
          render={() => <PublicLayout />}
        />
        <Route
          path={process.env.PUBLIC_URL + "/:lang(fa|en)?/user/:username"}
          render={() => <PublicLayout />}
          exact
        />
        <Route
          path={
            process.env.PUBLIC_URL +
            "/:lang(fa|en)?/payment/:authority/:signature"
          }
          render={() => <PublicLayout />}
          exact
        />
        <Route
          path={process.env.PUBLIC_URL + `/:lang(fa|en)?/auth`}
          render={() => <AuthLayout />}
        />

        {locale.locale ? (
          <Redirect
            from="/:lang(fa|en)?"
            to={process.env.PUBLIC_URL + `/${locale.locale}/auth/login`}
            exact
          />
        ) : (
          <Redirect from="/:lang(fa|en)?" to="/en/auth/login" />
        )}

        <Route
          path={process.env.PUBLIC_URL + "/:lang(fa|en)?"}
          render={() => <RTLLayout />}
        />
      </Switch>
    </Suspense>
  );
};

export default App;
