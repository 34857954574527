import { createStore, applyMiddleware } from "redux";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import rootReducer from "./rootReducer";
import { INIT_USER } from "../actions/index";

const config = {
  channel: "my_broadcast_channel",
  broadcastChannelOption: { type: "localstorage" },
  whitelist: [INIT_USER],
};
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
}
const middleware = [createStateSyncMiddleware(config)];

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

initMessageListener(store);

export const persistor = persistStore(store);

export default { store };
