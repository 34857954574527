import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./reducers/Store";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import Modal from "react-modal";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

Sentry.init({
  dsn: "https://55e3cc83168d5da35073ad3a6a0dd7e3@sentry.yekpay.com/7",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost:3000",
        /^https:\/\/dashboard\.yekpay\.com\/.*/,
      ],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

Modal.setAppElement("#root");

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
